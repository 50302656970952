
import { MenuInfo } from '@/_utils/types';
import { defineComponent } from 'vue'
import IconSVG from "@/components/IconSVG.vue";

export default defineComponent({
  components: {
    IconSVG
  },
  setup(props, { emit }) {
    const onClickMenuFilter = ({ key }: MenuInfo) => {
      emit("onFilter", +key)
    };
    return {
      onClickMenuFilter
    }
  }
})
