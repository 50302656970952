<template>
  <div class="filter-btn-wrapper">
    <a-dropdown overlayClassName="filter-status-menu-overlay">
      <a-button>
        <template #icon><IconSVG name="filter" /></template>
      </a-button>
      <template #overlay>
        <a-menu @click="onClickMenuFilter" :style="{ textAlign: 'center' }">
          <a-menu-item-group key="g1">
            <template #title>
              <p class="fw-bold text-dark fs-6 mb-0">สถานะการใช้งาน</p>
            </template>
            <a-menu-item key="1">ทั้งหมด</a-menu-item>
            <a-menu-item key="2">เปิดใช้งาน</a-menu-item>
            <a-menu-item key="3">ปิดใช้งาน</a-menu-item>
          </a-menu-item-group>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script lang="ts">
import { MenuInfo } from '@/_utils/types';
import { defineComponent } from 'vue'
import IconSVG from "@/components/IconSVG.vue";

export default defineComponent({
  components: {
    IconSVG
  },
  setup(props, { emit }) {
    const onClickMenuFilter = ({ key }: MenuInfo) => {
      emit("onFilter", +key)
    };
    return {
      onClickMenuFilter
    }
  }
})
</script>

