<template>
    <a-modal :width="768" centered :visible="visible" :footer="null" :closable="false">
        <div class="container">
            <a-alert v-if="message.text" class="mb-3" :message="message.text" :type="message.type" showIcon closable @close="() => {
                message.text = ''
                message.type = ''
            }" />
            <div class="d-flex justify-content-between mb-4">
                <span class="small sat-btn-link">เพิ่มประเภทสิทธิ์การใช้งาน</span>
                <div v-if="formRef">
                    <a-button type="link" class="me-2" @click="dismissForm">ละทิ้ง</a-button>
                    <a-button type="primary" :loading="isLoading" @click="handleFormFinish"
                        :disabled="!formState.nameEn || !formState.nameTh">บันทึก</a-button>
                </div>
            </div>
            <a-form layout="vertical" ref="formRef" :model="formState" @finish="handleFormFinish">
                <div class="row">
                    <div class="col-6">
                        <a-form-item label="ชื่อสิทธิ์การใช้งาน (ภาษาไทย)">
                            <a-input v-model:value="formState.nameTh" placeholder="ชื่อสิทธิ์การใช้งาน" size="large" />
                        </a-form-item>
                    </div>
                    <div class="col-6">
                        <a-form-item label="ชื่อสิทธิ์การใช้งาน (ภาษาอังกฤษ)">
                            <a-input v-model:value="formState.nameEn" placeholder="ชื่อสิทธิ์การใช้งาน" size="large" />
                        </a-form-item>
                    </div>
                </div>
                <p><span class="small sat-btn-link">การเข้าถึง</span></p>
                <div class="row g-0 py-3" style="backgroundColor: #FAFAFB;">
                    <div class="col-6 ps-4">เมนู</div>
                    <div class="col-3 text-center">สามารถเข้าถึงได้</div>
                    <div class="col-3 text-center" v-if="$route.name !=='kpiReport-permissions'" >สามารถแก้ไขได้ </div>
                </div>
                <!-- <template v-for="(x, idx) in permissionMenuList" :key="x">
                    <a-checkbox-group v-model:value="checkboxList[idx]" class="w-100" @change="(val) => onChange(x, val)">
                        <div class="row g-0 py-3 border-bottom" >
                            <div class="col-6 ps-4">{{x}}</div>
                            <div class="col-3 text-center">
                                <a-checkbox value="A"></a-checkbox>
                            </div>
                            <div class="col-3 text-center">
                                <a-checkbox value="B"></a-checkbox>
                            </div>
                        </div>
                    </a-checkbox-group>
                </template> -->
                <template v-for="(x) in checkboxListTmp" :key="x.key">
                    <a-checkbox-group v-model:value="x._" class="w-100" @change="(val) => onChange(x, val)">
                        <div class="row g-0 py-3 border-bottom">
                            <div class="col-6 ps-4">{{ x.key }}</div>
                            <div class="col-3 text-center">
                                <a-checkbox value="A"></a-checkbox>
                            </div>
                            <div class="col-3 text-center" v-if="$route.name !=='kpiReport-permissions'">
                                <a-checkbox value="B"></a-checkbox>
                            </div>
                        </div>
                    </a-checkbox-group>
                </template>
                <div class="mt-3 text-end">
                    <span class="me-2">สถานะการใช้งาน</span>
                    <a-switch v-model:checked="status">
                        <template #checkedChildren><check-outlined /></template>
                        <template #unCheckedChildren><close-outlined /></template>
                    </a-switch>
                    <span class="ms-2" v-if="status">เปิดใช้งาน</span>
                    <span class="ms-2" v-else>ปิดใช้งาน</span>
                </div>
            </a-form>
        </div>
    </a-modal>
</template>

<script lang="ts">
import { CheckOutlined, CloseOutlined } from '@ant-design/icons-vue';
import { defineComponent, UnwrapRef, ref, reactive, watch, toRefs, PropType, toRef } from "vue";
import { IPermission, EPermissionMenu, EPermissionMenuCheckList, EPermissionAcademyCheckList, EPermissionELearningCheckList, EPermissionReportCheckList } from "@/_utils/types";
import { useRequest } from 'vue-request';
import { bigDataService, academyService, eLearningService, reportService } from '@/_services';
import { useRoute, useRouter } from 'vue-router';
interface FormState {
    nameTh: string;
    nameEn: string;
}
export default defineComponent({
    props: {
        visible: Boolean,
        type: {
            type: String,
            validator: (val: string) => ["bigData", "academy", "eLearning", 'kpiReport'].includes(val),
        },
        permission: {
            type: Object as PropType<IPermission>,
            required: false
        }
    },
    components: {
        CheckOutlined,
        CloseOutlined
    },
    setup(props, { emit }) {
        const type = toRef(props, 'type')
        const service = type.value === 'bigData' ? bigDataService : (type.value === 'academy' ? academyService : (type.value === 'eLearning' ? eLearningService : reportService))
        const permissionMenuList = reactive([...EPermissionMenu])
        const checkbox = type.value === 'bigData' ? EPermissionMenuCheckList : (type.value === 'academy' ? EPermissionAcademyCheckList : (type.value === 'eLearning' ? EPermissionELearningCheckList : EPermissionReportCheckList))
        const permission = toRef(props, 'permission')
        const isLoading = ref(false)
        const formRef = ref()
        const route = useRoute()

        const formState: UnwrapRef<FormState> = reactive({
            nameTh: '',
            nameEn: ''
        });
        const state = reactive({
            checkboxListTmp: [...checkbox],
            status: false,
            message: {
                text: '',
                type: ''
            }
        });
        watch([permission], () => {
            state.checkboxListTmp = state.checkboxListTmp.map(ele => {
                ele._ = []
                return ele
            })
            // state.checkboxListTmp= checkbox.map((ele:any)=> {
            //     ele._ = []
            //     return ele
            // })
            if (!permission.value) {
                return;
            }
            const perm = permission.value
            const { permission_menu } = perm
            Object.keys(permission_menu).forEach(menuKey => {
                // eslint-disable-next-line no-prototype-builtins
                let tmp = state.checkboxListTmp.find(ele => ele.hasOwnProperty(menuKey)) as any
                if (tmp) {
                    tmp[menuKey] = permission_menu[menuKey]
                    if (permission_menu[menuKey] === true) {
                        if (menuKey.includes('_edit') && !tmp._.includes('B')) {
                            tmp._.push('B')
                        } else {
                            if (!tmp._.includes('A')) {
                                tmp._.push('A')
                            }
                        }
                    }
                }
            })
            formState.nameTh = perm.permission_name_th
            formState.nameEn = perm.permission_name_en
            state.status = perm.active ?? false
        }, { deep: true })

        const { data: updatedResult, run: updatePermission } = useRequest(service.updatePermission, {
            manual: true,
            // onSuccess:() => {
            //     isLoading.value = false
            //     if (updatedResult.value?.success) {
            //         state.message.text = 'Updated permission successful'
            //         state.message.type = 'info'
            //         setTimeout(()=>{
            //             dismissForm()
            //         }, 1000)
            //     }
            // },
            onError: (error) => {
                isLoading.value = false
                state.message.text = 'Cannot update permission, please try again later'
                state.message.type = 'error'
            }
        })
        watch([updatedResult], () => {
            isLoading.value = false
            if (updatedResult.value?.success) {
                state.message.text = 'Updated permission successful'
                state.message.type = 'info'
                setTimeout(() => {
                    dismissForm()
                }, 1000)
            }
        })
        const { data: createdResult, run: createPermission } = useRequest(service.createPermission, {
            manual: true,
            // onSuccess:() => {
            //     isLoading.value = false
            //     if (createdResult.value?.success) {
            //         state.message.text = 'Created permission successful'
            //         state.message.type = 'info'
            //         setTimeout(()=>{
            //             dismissForm()
            //         }, 1000)
            //     }
            // },
            onError: (error) => {
                isLoading.value = false
                state.message.text = 'Cannot create permission, please try again later'
                state.message.type = 'error'
            }
        })

        watch([createdResult], () => {
            isLoading.value = false
            if (createdResult.value?.success) {
                state.message.text = 'Created permission successful'
                state.message.type = 'info'
                setTimeout(() => {
                    dismissForm()
                }, 1000)
            }
        })
        const handleFormFinish = () => {

            const payload = {
                "permission_name_th": formState.nameTh,
                "permission_name_en": formState.nameEn,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                "permission_menu": state.checkboxListTmp.reduce((acc, cur: any) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    let result: any = {}
                    Object.keys(cur).filter(k => !['key', '_'].includes(k)).forEach(ele => {
                        result[ele] = cur[ele]
                    })
                    return Object.assign(acc, result)
                }, {}),
                "active": state.status
            }

            isLoading.value = true
            if (permission.value) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                updatePermission(permission.value.id!, payload)
            } else {
                createPermission(payload)
            }
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onChange = (changed: any, checkedValue: string[]) => {
            if (checkedValue.includes("B")) {
                changed._ = ["A", "B"]
                Object.keys(changed).filter(k => !['key', '_'].includes(k)).forEach(e => {
                    changed[e] = true
                })
            } else if (checkedValue.length) {
                changed._ = ["A"]
                Object.keys(changed).filter(k => !['key', '_'].includes(k)).forEach(e => {
                    changed[e] = !e.includes('_edit')
                })
            } else {
                changed._ = []
                Object.keys(changed).filter(k => !['key', '_'].includes(k)).forEach(e => {
                    changed[e] = false
                })
            }
        }
        const dismissForm = () => {
            formState.nameTh = ''
            formState.nameEn = ''
            formRef.value.resetFields()
            state.checkboxListTmp = [...checkbox]
            state.message.type = '';
            state.message.text = '';
            emit('closeModal')
        }

        return {
            formRef,
            // router,
            formState,
            ...toRefs(state),
            handleFormFinish,
            dismissForm,
            onChange,
            permissionMenuList,
            EPermissionMenuCheckList,
            EPermissionAcademyCheckList,
            isLoading
        }
    }
})
</script>

