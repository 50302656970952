<template>
  <div class="text-end mb-3">
    <a-button class="text-end mb-1" type="primary" @click="addPermission">
      <template #icon><IconSVG name="plus" /></template
      >เพิ่มประเภทสิทธิ์การใช้งาน
    </a-button>
  </div>
  <a-card class="w-100 h-auto" :bodyStyle="{ padding: '24px 15px' }">
    <div class="d-flex mb-3">
      <div class="search-wrapper me-2">
        <a-input-search
          placeholder="ค้นหาชื่อบัญชี"
          class="w-100"
          @search="onSearch"
          v-model:value="searchInput"
          :enter-button="undefined"
        >
          <template #prefix>
            <IconSVG name="search" />
          </template>
        </a-input-search>
      </div>
      <filter-status-menu @on-filter="onClickMenuFilter" />
    </div>
    <a-table
      :columns="columns"
      :row-key="(record) => record.id"
      :data-source="dataSource"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <template #date="{ text }">
        {{ format(new Date(text), "dd/MM/yyyy") }}
      </template>
      <template #status="{ text, record }">
        <!-- <a-switch v-model:checked="statusList[index]" /> {{text ? 'เปิดใช้งาน': 'ปิดใช้งาน'}} -->
        <a-switch v-model:checked="record.active" @change="(val) => onSwitch(record, val)" class="me-2" />{{text ? 'เปิดใช้งาน': 'ปิดใช้งาน'}}
      </template>
      <template #action="{ record }">
        <a-tooltip>
          <template #title>แก้ไขข้อมูลสิทธิใช้งาน</template>
          <a @click="handleClickEdit(record)" class="fw-light me-3">
            <EditOutlined />
          </a>
        </a-tooltip>
        <!-- <a-divider type="vertical" />
        <a-popconfirm
          placement="leftTop"
          title="ลบระดับสิทธิใช้งาน?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="confirmDelete(index)"
        >
          <DeleteOutlined />
        </a-popconfirm> -->
      </template>
    </a-table>
  </a-card>
  <PermissionModal
    v-model:visible="visibleModal"
    type="bigData"
    :permission="currentEditPermission"
    @close-modal="onCloseModal"
  />
</template>
<script lang="ts">
import { defineComponent, computed, ref, Ref,watch } from "vue";
import { usePagination, useRequest } from "vue-request";
import { format } from "date-fns";
import { message } from 'ant-design-vue';
import {
  TableState,
  TableStateFilters,
} from "ant-design-vue/es/table/interface";
import { /* EllipsisOutlined, DeleteOutlined,*/ EditOutlined } from "@ant-design/icons-vue";
import IconSVG from "@/components/IconSVG.vue";
import { permissionsColumns as columns } from "../_components/columns";
import FilterStatusMenu from "../_components/FilterStatusMenu.vue";
import PermissionModal from "../_components/PermissionModal.vue";
import { bigDataService } from "@/_services";
import { IPageResponse, IPermission } from "@/_utils/types";
type Pagination = TableState["pagination"];

export default defineComponent({
  components: {
    IconSVG,
    // DeleteOutlined,
    EditOutlined,
    PermissionModal,
    FilterStatusMenu
  },
  setup() {
    const total = ref<number>(0);
    const visibleModal = ref<boolean>(false);
    const statusList = ref<boolean[]>([])
    const currentEditPermission = ref<IPermission>()
    const { data: dataSource, run, loading, current, pageSize } = usePagination(
      bigDataService.getPermissions,
      {
        defaultParams: [{ page: 1, size: 5 }],
        pagination: { pageSizeKey: "size", currentKey: "page" },
        formatResult: (result) => {
          //statusList.value = Array(pageSize.value).fill(false);
          const { data: tmp } = result.data as IPageResponse<IPermission[]>
          tmp.forEach((ele: IPermission) => {
            statusList.value?.push(ele.active ?? false)
          })
          total.value = result.data?.total ?? 0;
          return result.data?.data;
        },
      }
    );
    const pagination = computed(() => ({
      total: total.value,
      current: current.value,
      pageSize: pageSize.value,
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20"],
      showTotal: (total: number, range: number[]) =>
        `${range[0]}-${range[1]} จาก ${total} รายการ`,
      buildOptionText: (size: Ref<number>) => {
        return `${size.value}`;
      },
    }));
    const handleTableChange = (
      pag: Pagination,
      filters: TableStateFilters,
      sorter: any
    ) => {
      run({
        size: pag?.pageSize ?? 5,
        ...(pag?.current && { page: pag.current }),
      });
    };
    const onSearch = (searchValue: string) => {
      run({
        name: searchValue,
        page: 1,
        size: pageSize.value,
      });
    };
    const addPermission = () => {
      currentEditPermission.value = undefined
      visibleModal.value = true;
    };
    const confirmDelete = (idx: number) => {
      // message.success('Next step.');
    };
    const handleClickEdit = (value: IPermission) => {
      currentEditPermission.value = value
      visibleModal.value = true;
    }
    const { data: updatedBigData, run: updateBigData } = useRequest(bigDataService.updatePermission, {
        manual: true,

        onError: (error) => {
            loading.value = false
            message.error('Cannot update permission, please try again later');
        }
    })
    watch([updatedBigData], () => {
       loading.value = false
            if (updatedBigData.value?.success) {
              message.info('Updated permission successful');
            } else {
              message.error('Cannot update permission, please try again later');
            }
    });
    const onSwitch = (record: IPermission, val: boolean) => {
      loading.value = true
      const { id, permission_name_en, permission_name_th, active, permission_menu } = record
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      updateBigData(id!, {
        permission_name_th,
        permission_name_en,
        active: active ?? false,
        permission_menu
      })
    }

    const onCloseModal = () => {
      visibleModal.value = false
      run({
        page: 1,
        size: pageSize.value,
      });
    }

    const searchInput = ref<string>()
    const onClickMenuFilter = (key: number) => {
      searchInput.value = ''
      run({
        ...( key > 1 && {active : key == 3 ? 'false': 'true'} ),
        page: 1,
        size: pageSize.value,
      });
    };

    return {
      dataSource,
      pagination,
      loading,
      columns,
      handleTableChange,
      onSearch,
      addPermission,
      visibleModal,
      confirmDelete,
      format,
      statusList,
      handleClickEdit,
      currentEditPermission,
      onSwitch,
      onCloseModal,
      onClickMenuFilter,
      searchInput
    };
  },
});
</script>
<style scoped lang="scss">
.search-wrapper {
  width: 95%;
  :deep(.sat-input-suffix) {
    display: none;
  }
}
</style>
